//eslint-disable-next-line
__webpack_public_path__ = window.__wpcal_dist_url;

// As import files are hoisted "window.__wpcal_client_end = "user";" was not running, resulted in unexpected results. Now the same code run using import "./utils/client_end_user.js";
import "./utils/client_end_user.js";

import Vue from "vue";
import UserApp from "./UserApp.vue";
import router from "./router/user.js";
import store from "./store/user.js";

import "./utils/common_mixin.js";
import "./utils/filters.js";
import "./utils/http.js";

import VModal from "vue-js-modal";
Vue.use(VModal, { dialog: true });

//import VCalendar from "v-calendar";
//Vue.use(VCalendar);

import DatePicker from "v-calendar/lib/components/date-picker.umd";
Vue.component("v-date-picker", DatePicker);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import vuelidateErrorExtractor from "vuelidate-error-extractor";
import vuelidateErrorExtractorOptions from "./utils/vuelidate_error_extractor_options.js";
Vue.use(vuelidateErrorExtractor, vuelidateErrorExtractorOptions);

import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
const defaultVueIziToastOptions = {
  timeout: 5000,
  position: "topRight",
  drag: false
};
Vue.use(VueIziToast, defaultVueIziToastOptions);

Vue.config.productionTip = false;

import { __, _x, _n, _nx, Tsprintf, debug_log } from "@/utils/common_func.js";
Vue.prototype.__ = __;
Vue.prototype._x = _x;
Vue.prototype._n = _n;
Vue.prototype._nx = _nx;
Vue.prototype.T__ = __;
Vue.prototype.T_x = _x;
Vue.prototype.T_n = _n;
Vue.prototype.T_nx = _nx;
Vue.prototype.Tsprintf = Tsprintf;

// document.addEventListener("DOMContentLoaded", () => {
//   new Vue({
//     router,
//     store,
//     render: h => h(UserApp)
//   }).$mount("#wpcal_user_app");
// });

let user_view_instance = null;
let wpcal_load_trigger = new Event("wpcal_load_booking_widget");
let elementor_delay_done = false;
let vue_initiated_once = false;

document.addEventListener("wpcal_load_booking_widget", () => {
  debug_log("event fired received: wpcal_load_booking_widget");
  let widget_element = document.querySelector(
    "#wpcal_user_app.loading-indicator-initial"
  );
  if (widget_element) {
    if (
      vue_initiated_once === false &&
      elementor_delay_done === false &&
      widget_element.closest(".elementor-location-popup") &&
      !widget_element.closest(".elementor-popup-modal")
    ) {
      /* While using booking widget in elementor popup, widget html content loads in DOM and then removed by elementor and then re-adds it in different location that is in .elementor-popup-modal, in this gap if Vue is initiated its get stuck without giving error as the mount is removed from dom. so we can delay vue initation */
      //elementor content not in the popup, so delay vue initiation
      console.log("WPCal - Delaying check for elementor workaround");
      setTimeout(() => {
        elementor_delay_done = true;
        document.dispatchEvent(wpcal_load_trigger);
      }, 600);
      return;
    }

    if (user_view_instance) {
      user_view_instance.$destroy();
      user_view_instance = null;
    }
    if (!user_view_instance) {
      debug_log("Triggering wpcal vue instance");
      vue_initiated_once = true;
      user_view_instance = new Vue({
        router,
        store,
        render: h => h(UserApp)
      }).$mount("#wpcal_user_app");
    }
  }
});

try {
  setTimeout(() => {
    debug_log("WPCal dispatch event after Vue load");
    document.dispatchEvent(wpcal_load_trigger);
  }, 10);
} catch (err) {}
