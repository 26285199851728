<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    check_and_route() {
      if (this.$route.name == "user_home") {
        if (
          typeof wpcal_booking_service_id !== "undefined" &&
          wpcal_booking_service_id //eslint-disable-line
        ) {
          this.$router.push("booking");
        }
      }
    }
  },
  mounted() {
    this.check_and_route();
  }
};
</script>
