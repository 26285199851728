import Vue from "vue";
import VueRouter from "vue-router";

import { get_url_param_by_name } from "@/utils/common_func.js";

import UserHome from "../views/user/UserHome.vue";
import Booking from "../views/user/Booking.vue";
import BookingStep2 from "../components/user/BookingStep2.vue";
import BookingView from "../views/user/BookingView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "user_home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UserHome
    // component: () => import("../views/user/UserHome.vue")
  },
  {
    path: "/booking",
    name: "booking",
    children: [
      {
        path: "reschedule/:unique_link/",
        name: "booking_reschedule"
      },
      {
        path: "schedule_again/:unique_link/",
        name: "booking_schedule_again"
      },
      {
        path: "confirm",
        name: "booking_confirm",
        components: {
          step2: BookingStep2
          // step2: () => import("../components/user/BookingStep2.vue")
        }
      }
    ],
    component: Booking
    // component: () => import("../views/user/Booking.vue")
  },
  {
    path: "/booking/view/:unique_link",
    name: "booking_view",
    component: BookingView,
    // component: () => import("../views/user/BookingView.vue"),
    children: [
      {
        path: "confirmed",
        name: "booking_view_confirmed"
      },
      {
        path: "rescheduled",
        name: "booking_view_rescheduled"
      },
      {
        path: "cancelled",
        name: "booking_view_cancelled"
      },
      {
        path: "cancel",
        name: "booking_view_cancel"
      }
    ]
  }
];

const router = new VueRouter({
  mode: "abstract",
  //mode: "history",
  //base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  let handle_push_state = to => {
    //console.log("to", to);
    let full_path = to.fullPath;
    let current_url = window.location.href;
    let url_obj = new URL(current_url);
    url_obj.searchParams.set("wpcal_r", full_path);

    if (full_path == "/" || full_path == "/booking") {
      url_obj.searchParams.delete("wpcal_r");
    }
    let history_url = url_obj.toString();

    let param_route = get_url_param_by_name("wpcal_r");
    if (param_route != full_path) {
      //console.log("history_url  change", history_url);
      window.history.pushState(null, "", history_url);
    }
  };
  handle_push_state(to);
});

export default router;
