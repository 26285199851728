var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"wpcal-widget"}},[_c('div',{staticClass:"widget-main state-confirmation cf"},[_c('div',{staticClass:"event-preset"},[_c('img',{attrs:{"src":_vm.service_admin_details.profile_picture}}),_c('div',{staticClass:"inviter-name"},[_vm._v(" "+_vm._s(_vm.service_admin_details.display_name)+" ")]),_c('div',{staticClass:"confimation-confirmed"},[_vm._v(" "+_vm._s(_vm.__("Hello", "wpcal"))+" "+_vm._s(_vm._f("wpcal_get_firstname")(_vm.booking_details.invitee_name))+","),_c('br'),_vm._v(" "+_vm._s(_vm.booking_status_txt)+" ")]),_c('div',{staticClass:"event-type-name",class:( _obj = {}, _obj['clr-' + _vm.service_details.color] = true, _obj )},[_vm._v(" "+_vm._s(_vm.service_details.name)+" ")]),(_vm.booking_details.status == '-5' && _vm.redirect_text)?_c('div',[_vm._v(" "+_vm._s(_vm.redirect_text)+" ")]):_vm._e(),(_vm.booking_details.status == '1')?_c('div',[_c('div',{staticClass:"event-type-duration",domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
                _vm.booking_details.booking_from_time,
                _vm.booking_details.booking_to_time,
                _vm.tz,
                _vm.time_format
              ) + '.'
            )}}),_c('LocationAdvanced',{attrs:{"type":"display_user_after_booking_location","location_details":_vm.booking_details.location,"service_admin_details":_vm.service_admin_details}})],1):_vm._e(),(
            (_vm.$route.name == 'booking_view_confirmed' ||
              _vm.$route.name == 'booking_view_rescheduled') &&
              _vm.booking_details.status == 1 &&
              _vm.booking_details.is_invitee_notify_by_email &&
              _vm.booking_details.is_invitee_notify_by_calendar
          )?_c('div',{staticClass:"txt-h4 confimation-via"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.Tsprintf(
                /* translators: %s: html-tag */
                _vm.__(
                  'A booking confimation has been sent to %s your email address -',
                  'wpcal'
                ),
                '<br />'
              )
            )}}),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(/* following spacing required */ " " + _vm.booking_details.invitee_email))])]):(
            (_vm.$route.name == 'booking_view_confirmed' ||
              _vm.$route.name == 'booking_view_rescheduled') &&
              _vm.booking_details.status == 1 &&
              _vm.booking_details.is_invitee_notify_by_calendar &&
              !_vm.booking_details.is_invitee_notify_by_email
          )?_c('div',{staticClass:"txt-h4 confimation-via"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.Tsprintf(
                /* translators: %s: html-tag */
                _vm.__(
                  'A calendar invitation has been sent to %s your email address -',
                  'wpcal'
                ),
                '<br />'
              )
            )}}),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(/* following spacing required */ " " + _vm.booking_details.invitee_email))])]):(
            _vm.booking_details.status == 1 &&
              !_vm.booking_details.is_invitee_notify_by_calendar
          )?_c('div',{staticClass:"txt-h4 confimation-via"},[_c('a',{staticStyle:{"color":"#567bf3","text-decoration":"underline"},attrs:{"href":_vm.booking_details.add_event_to_google_calendar_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.__("Add to", "wpcal"))+" Google Calendar")]),_vm._v("→ "),_c('a',{staticStyle:{"color":"#567bf3","text-decoration":"underline"},attrs:{"href":_vm.booking_details.download_ics_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.__("Add to", "wpcal"))+" iCal/Outlook ")]),_vm._v("→ ")]):_vm._e(),(
            (_vm.$route.name == 'booking_view' ||
              _vm.$route.name == 'booking_view_cancel') &&
              !_vm.is_booking_slot_time_passed
          )?_c('div',{staticStyle:{"margin-top":"20px"}},[(_vm.can_show_rechedule_option)?_c('a',{staticClass:"btn",staticStyle:{"text-decoration":"underline"},attrs:{"href":'?wpcal_r=' +
                encodeURIComponent(
                  '/booking/reschedule/' + _vm.booking_unique_link
                )},on:{"click":function($event){return _vm.on_click_if_same_page_target_router_push(
                $event,
                '/booking/reschedule/' + _vm.booking_unique_link
              )}}},[_vm._v(_vm._s(_vm.__("Reschedule", "wpcal")))]):_vm._e(),(_vm.can_show_cancel_option)?_c('a',{staticClass:"btn cancel pointer",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.show_cancel_confirmation(_vm.booking_details)}}},[_vm._v(" "+_vm._s(_vm.__("Cancel", "wpcal"))+" ")]):_vm._e()]):_vm._e(),(!_vm._isEmpty(_vm.booking_details.invitee_question_answers))?_c('div',[_c('div',[(!_vm.show_question_answers)?_c('a',{staticClass:"btn pointer",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.show_question_answers = true}}},[_vm._v(_vm._s(_vm.__("Show submitted form", "wpcal")))]):_vm._e(),(_vm.show_question_answers)?_c('a',{staticClass:"btn pointer",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){_vm.show_question_answers = false}}},[_vm._v(_vm._s(_vm.__("Hide submitted form", "wpcal")))]):_vm._e()]),(_vm.show_question_answers)?_c('div',{staticClass:"event-survey"},_vm._l((_vm.booking_details.invitee_question_answers),function(question,index){return _c('div',{key:index,staticClass:"wpc-form-row"},[_c('div',{staticClass:"txt-h5 pre",staticStyle:{"color":"#7c7d9c","margin-bottom":"5px"}},[_vm._v(_vm._s(question.question))]),(question.answer == '')?_c('div',{staticClass:"pre",staticStyle:{"margin-bottom":"10px","font-size":"13px","font-style":"italic"}},[_vm._v(" ("+_vm._s(_vm.__("Not entered", "wpcal"))+") ")]):_c('div',{staticClass:"pre b",staticStyle:{"margin-bottom":"10px"}},[_vm._v(_vm._s(question.answer))])])}),0):_vm._e()]):_vm._e()])]),_c('BookingBottomPoweredBy')],1),_c('BookingCancelModal',{attrs:{"booking_details":_vm.booking_details,"cancel_booking_btn_is_enabled":_vm.cancel_booking_btn_is_enabled},on:{"cancel-booking":function($event){return _vm.cancel_booking($event)}},model:{value:(_vm.cancel_reason),callback:function ($$v) {_vm.cancel_reason=$$v},expression:"cancel_reason"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }