<template>
  <div>
    <div id="wpcal-widget">
      <div class="widget-main state-confirmation cf">
        <div class="event-preset">
          <img :src="service_admin_details.profile_picture" />
          <div class="inviter-name">
            {{ service_admin_details.display_name }}
          </div>
          <div class="confimation-confirmed">
            {{ __("Hello", "wpcal") }}
            {{ booking_details.invitee_name | wpcal_get_firstname }},<br />
            {{ booking_status_txt }}
          </div>
          <div
            class="event-type-name"
            :class="{ ['clr-' + service_details.color]: true }"
          >
            {{ service_details.name }}
          </div>
          <div v-if="booking_details.status == '-5' && redirect_text">
            {{ redirect_text }}
          </div>
          <div v-if="booking_details.status == '1'">
            <div
              class="event-type-duration"
              v-html="
                $options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
                  booking_details.booking_from_time,
                  booking_details.booking_to_time,
                  tz,
                  time_format
                ) + '.'
              "
            ></div>
            <!-- <div class="event-type-location" v-if="booking_details.location">
              {{ booking_details.location }}
            </div> -->
            <LocationAdvanced
              type="display_user_after_booking_location"
              :location_details="booking_details.location"
              :service_admin_details="service_admin_details"
            />
          </div>
          <div
            class="txt-h4 confimation-via"
            v-if="
              ($route.name == 'booking_view_confirmed' ||
                $route.name == 'booking_view_rescheduled') &&
                booking_details.status == 1 &&
                booking_details.is_invitee_notify_by_email &&
                booking_details.is_invitee_notify_by_calendar
            "
          >
            <span
              v-html="
                Tsprintf(
                  /* translators: %s: html-tag */
                  __(
                    'A booking confimation has been sent to %s your email address -',
                    'wpcal'
                  ),
                  '<br />'
                )
              "
            ></span>
            <span style="font-weight:500;">{{
              /* following spacing required */ " " +
                booking_details.invitee_email
            }}</span>
          </div>
          <div
            class="txt-h4 confimation-via"
            v-else-if="
              ($route.name == 'booking_view_confirmed' ||
                $route.name == 'booking_view_rescheduled') &&
                booking_details.status == 1 &&
                booking_details.is_invitee_notify_by_calendar &&
                !booking_details.is_invitee_notify_by_email
            "
          >
            <span
              v-html="
                Tsprintf(
                  /* translators: %s: html-tag */
                  __(
                    'A calendar invitation has been sent to %s your email address -',
                    'wpcal'
                  ),
                  '<br />'
                )
              "
            ></span>
            <span style="font-weight:500;">{{
              /* following spacing required */ " " +
                booking_details.invitee_email
            }}</span>
          </div>
          <div
            class="txt-h4 confimation-via"
            v-else-if="
              booking_details.status == 1 &&
                !booking_details.is_invitee_notify_by_calendar
            "
          >
            <a
              style="color: #567bf3; text-decoration: underline;"
              :href="booking_details.add_event_to_google_calendar_url"
              target="_blank"
              >{{ __("Add to", "wpcal") }} Google Calendar</a
            >&rarr;

            <a
              style="color: #567bf3; text-decoration: underline;"
              :href="booking_details.download_ics_url"
              target="_blank"
              >{{ __("Add to", "wpcal") }} iCal/Outlook </a
            >&rarr;
          </div>
          <div
            style="margin-top:20px;"
            v-if="
              ($route.name == 'booking_view' ||
                $route.name == 'booking_view_cancel') &&
                !is_booking_slot_time_passed
            "
          >
            <a
              style="text-decoration:underline;"
              class="btn"
              :href="
                '?wpcal_r=' +
                  encodeURIComponent(
                    '/booking/reschedule/' + booking_unique_link
                  )
              "
              @click="
                on_click_if_same_page_target_router_push(
                  $event,
                  '/booking/reschedule/' + booking_unique_link
                )
              "
              v-if="can_show_rechedule_option"
              >{{ __("Reschedule", "wpcal") }}</a
            >
            <a
              style="text-decoration:underline;"
              class="btn cancel pointer"
              @click="show_cancel_confirmation(booking_details)"
              v-if="can_show_cancel_option"
            >
              {{ __("Cancel", "wpcal") }}
            </a>
          </div>

          <!--View Submitted form-->
          <div v-if="!_isEmpty(booking_details.invitee_question_answers)">
            <div>
              <a
                style="text-decoration:underline;"
                class="btn pointer"
                v-if="!show_question_answers"
                @click="show_question_answers = true"
                >{{ __("Show submitted form", "wpcal") }}</a
              ><a
                style="text-decoration:underline;"
                class="btn pointer"
                v-if="show_question_answers"
                @click="show_question_answers = false"
                >{{ __("Hide submitted form", "wpcal") }}</a
              >
            </div>
            <div class="event-survey" v-if="show_question_answers">
              <div
                class="wpc-form-row"
                v-for="(question,
                index) in booking_details.invitee_question_answers"
                :key="index"
              >
                <!-- eslint-disable-next-line --><!-- disable for pre -->
                <div class="txt-h5 pre" style="color: #7c7d9c; margin-bottom:5px;" >{{ question.question }}</div>
                <div
                  class="pre"
                  v-if="question.answer == ''"
                  style="margin-bottom:10px; font-size:13px; font-style: italic;"
                >
                  ({{ __("Not entered", "wpcal") }})
                </div>
                <!-- eslint-disable-next-line --><!-- disable for pre -->
                <div class="pre b" v-else style="margin-bottom:10px;">{{ question.answer }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingBottomPoweredBy />
      <!-- <div class="scheduled-invitee-trial cf">
        <div class="txt-h4" style="margin-bottom: 10px; font-size:14px;">
          Want your own scheduler for Free?
        </div>
        <div class="wpc-form">
          <input type="email" :value="booking_details.invitee_email" /><input
            type="submit"
            value="Try it now"
          />
        </div>
      </div> -->
    </div>
    <!-- <modal
      name="booking_cancel_confirm_modal"
      style="z-index:9999;"
      :height="'auto'"
      :adaptive="true"
    >
      <div class="modal_close_cont">
        <button @click="$modal.hide('booking_cancel_confirm_modal')"></button>
      </div>
      <div style="font-size: 16px; padding: 20px;">
        <div>
          {{ __("Are you sure you want to cancel this meeting?", "wpcal") }}
        </div>
        {{
          booking_details.booking_from_time
            | wpcal_unix_to_from_to_time_full_date_day_tz(
              booking_details.booking_to_time,
              tz,
              time_format
            )
        }}.
        <br />
        <div style="margin-top: 10px;">
          {{ __("Reason for cancellation:", "wpcal") }}
        </div>
        <div class="wpc-form-row" style="width:90%;">
          <textarea v-model="cancel_reason" rows="2" />
        </div>
        <div>
          <button
            class="wpc-btn primary lg"
            style="margin:10px 10px 10px 0; padding-left:10px; padding-right:10px;"
            type="button"
            :disabled="!cancel_booking_btn_is_enabled"
            @click="cancel_booking(booking_details)"
          >
            {{ __("Yes, Cancel it.", "wpcal") }}
          </button>
          <button
            class="wpc-btn primary lg"
            style="margin:10px 10px 10px 0; padding-left:10px; padding-right:10px;"
            type="button"
            @click="$modal.hide('booking_cancel_confirm_modal')"
          >
            {{ __("No, don't.", "wpcal") }}
          </button>
        </div>
      </div>
    </modal> -->
    <BookingCancelModal
      :booking_details="booking_details"
      :cancel_booking_btn_is_enabled="cancel_booking_btn_is_enabled"
      v-model="cancel_reason"
      @cancel-booking="cancel_booking($event)"
    />
  </div>
</template>
<style scoped>
a.btn {
  display: inline-block !important;
  color: #567bf3;
  font-size: 14px;
  margin: 10px;
}
</style>
<script>
import LocationAdvanced from "@/components/LocationAdvanced.vue";
import BookingCancelModal from "@/components/BookingCancelModal.vue";
import BookingBottomPoweredBy from "@/components/user/BookingBottomPoweredBy.vue";

import axios from "axios";

export default {
  components: {
    LocationAdvanced,
    BookingBottomPoweredBy,
    BookingCancelModal
  },
  data() {
    return {
      service_details: {},
      service_admin_details: {},
      booking_details: {},
      booking_unique_link: "",
      cancel_reason: "",
      redirect_text: "",
      cancel_booking_btn_is_enabled: true,
      show_question_answers: false
    };
  },
  computed: {
    booking_status_txt() {
      let success_txt = "";
      if (!this.booking_details.hasOwnProperty("status")) {
        success_txt = "...";
      } else if (this.booking_details.status == "1") {
        success_txt = this.__("our meeting is confirmed!", "wpcal");
        if (this.$route.name === "booking_view_rescheduled") {
          success_txt = this.__(
            "our meeting is rescheduled successfully!",
            "wpcal"
          );
        }
      } else if (
        this.booking_details.status == "-1" ||
        this.booking_details.status == "-2"
      ) {
        success_txt = this.__("our meeting is cancelled!", "wpcal");
        if (this.$route.name === "booking_view_cancelled") {
          success_txt = this.__(
            "our meeting is cancelled successfully!",
            "wpcal"
          );
        }
      } else if (this.booking_details.status == "-5") {
        success_txt = this.__("our meeting is rescheduled!", "wpcal");
      } else {
        success_txt = this.__("Technical error with meeting status.", "wpcal");
      }

      return success_txt;
    },
    is_booking_slot_time_passed() {
      //is booking to time is over
      let current_ts = Math.round(new Date().getTime() / 1000);
      return (
        this.booking_details &&
        this.booking_details.booking_to_time < current_ts
      );
    },
    can_show_rechedule_option() {
      return (
        this.booking_details &&
        this.booking_details.status == "1" &&
        !this.is_booking_slot_time_passed
      );
    },
    can_show_cancel_option() {
      return (
        this.booking_details &&
        this.booking_details.status == "1" &&
        !this.is_booking_slot_time_passed
      );
    }
  },
  methods: {
    load_booking_view() {
      this.booking_unique_link = this.$route.params.unique_link;

      let action = "view_booking";
      let wpcal_request = {};
      wpcal_request[action] = {
        unique_link: this.booking_unique_link
      };

      let post_data = {
        action: "wpcal_process_user_ajax_request",
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (response.data[action].status == "success") {
            this.booking_details = response.data[action].booking_data;
            this.service_details = response.data[action].service_data;
            this.service_admin_details =
              response.data[action].service_admin_data;
            this.may_redirect_to_new_booking();

            if (this.$route.name === "booking_view_cancel") {
              this.$nextTick(() => {
                if (this.can_show_cancel_option) {
                  this.show_cancel_confirmation();
                }
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    may_redirect_to_new_booking() {
      if (
        this.booking_details.status == "-5" &&
        this.booking_details.rescheduled_booking_unique_link
      ) {
        this.redirect_text = this.__("Redirecting to new booking...", "wpcal");
        setTimeout(() => {
          this.$router.push(
            "/booking/view/" +
              this.booking_details.rescheduled_booking_unique_link
          );
        }, 2000);
      }
    },
    show_cancel_confirmation() {
      this.$modal.show("booking_cancel_confirm_modal");
    },
    cancel_booking(booking) {
      this.cancel_booking_btn_is_enabled = false;
      let wpcal_request = {};
      let action = "cancel_booking";

      wpcal_request[action] = {
        booking_unique_link: booking.unique_link,
        cancel_reason: this.cancel_reason
      };

      let post_data = {
        action: "wpcal_process_user_ajax_request",
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          this.notify_single_action_result(response.data[action], {
            dont_notify_success: true
          });
          if (response.data[action].status == "success") {
            let wpcal_booking_trigger = new CustomEvent(
              "wpcal_booking_cancelled",
              {
                detail: {
                  booking_details: { unique_link: booking.unique_link }
                }
              }
            );
            document.dispatchEvent(wpcal_booking_trigger);
            this.$modal.hide("booking_cancel_confirm_modal");
            this.$router.push(
              "/booking/view/" + booking.unique_link + "/cancelled"
            );
          }
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.$store.dispatch("run_booking_background_tasks_by_unique_link", {
            unique_link: booking.unique_link
          });
          this.cancel_booking_btn_is_enabled = true;
        });
    }
  },
  watch: {
    "$route.name": function() {
      Object.assign(this.$data, this.$options.data.apply(this)); //re-initilize data in Vue
      this.load_booking_view();
    },
    "$route.params.unique_link": function() {
      Object.assign(this.$data, this.$options.data.apply(this)); //re-initilize data in Vue
      this.load_booking_view();
    }
  },
  mounted() {
    this.load_booking_view();
  }
};
</script>

<style lang="css" src="@/assets/css/user_booking.css"></style>

<style scoped>
.confimation-via {
  margin: 30px 0 0;
  font-size: 16px;
  line-height: 1.4em;
}
.event-survey {
  text-align: initial;
}
</style>
