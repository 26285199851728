<template>
  <div
    id="wpcal_user_app"
    :class="[main_cont_class]"
    :style="user_app_custom_styles"
  >
    <IconFillSVG />

    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
    </div> -->
    <router-view />
  </div>
</template>
<script>
import { get_url_param_by_name, hex_to_rgb_txt } from "@/utils/common_func.js";
import IconFillSVG from "@/assets/images/IconFillSVG.vue";

export default {
  components: {
    IconFillSVG
  },
  computed: {
    main_cont_class() {
      let app_cont_width = this.$store.getters.get_app_cont_width;
      if (!app_cont_width || app_cont_width < 700) {
        return "narrow";
      } else if (app_cont_width >= 700 && app_cont_width < 1000) {
        return "extended";
      } else if (app_cont_width >= 1000) {
        return "wide";
      }
      return "narrow";
    },
    branding_styles() {
      let styles = {};
      let general_setting = this.$store.getters.get_general_settings;

      if (
        general_setting.hasOwnProperty("branding_color") &&
        general_setting.branding_color
      ) {
        styles["--accentClrRGB"] = hex_to_rgb_txt(
          general_setting.branding_color
        );
      }

      return styles;
    },
    user_app_custom_styles() {
      let branding_styles = this.branding_styles;
      let css_varaibles = this.get_css_varaibles();
      let styles = Object.assign({}, branding_styles, css_varaibles);
      return styles;
    }
  },
  methods: {
    handle_router_request() {
      //console.log("handle_router_request triggered");

      let param_route = get_url_param_by_name("wpcal_r");
      //console.log("param_route", param_route);
      if (!param_route || param_route == "/" || param_route == "/booking") {
        this.$router.push("/booking");
      } else {
        this.$router.push(param_route);
      }
    },
    get_css_varaibles() {
      let css_variables = {};
      css_variables["--wpcal-content-today"] =
        "'" +
        this._x("TODAY", "Date picker today date highlight", "wpcal") +
        "'";
      return css_variables;
    },
    may_apply_branding_font() {
      //due to some local scope issue, this methdd works, that why not used in branding_styles()
      setTimeout(() => {
        let general_setting = this.$store.getters.get_general_settings;
        if (this._isEmpty(general_setting)) {
          this.may_apply_branding_font();
          return;
        }
        if (
          general_setting.hasOwnProperty("branding_font") &&
          general_setting.branding_font
        ) {
          let style_element = document.createElement("style");
          style_element.type = "text/css";
          style_element.innerHTML =
            ":root{--font-family:" +
            general_setting.branding_font +
            "; --font-family-med:" +
            general_setting.branding_font +
            "; --font-family-med-weight:bold;}";
          document.body.appendChild(style_element);
        }
      }, 50);
    }
  },
  created() {
    this.$store.dispatch("init");
    this.handle_router_request();
    window.addEventListener("popstate", state => {
      //console.log("popstate triggered");
      this.handle_router_request();
    });
    this.may_apply_branding_font();
  }
};
</script>

<style lang="scss"></style>
<style scoped src="@/assets/fonts/wf-stylesheet.css"></style>
