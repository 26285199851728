import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import common_module from "@/store/common_module.js";

export default new Vuex.Store({
  strict: true,
  modules: {
    common: common_module
  },
  state: {
    client_end: "user"
  }
  // mutations: {},
  // getters: {},
  // actions: {}
});
